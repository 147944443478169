section.paragraph.paragraph-text {
  &.background-color {
    .text {
      padding: rem-calc(30) rem-calc(40);
      background: $white;
    }
  }
  h3 {
    @include body(m);
  }
  .text {
    @include body(m);
    * {
      @include body(m);
    }
  }
}
