section.filter-cloud {
  padding: rem-calc(15) calc(#{$grid-gutter-width} / 2);
  padding-top: rem-calc(30);
  background: var(--module-color);
  position: sticky;
  top: var(--sticky-top);
  z-index: 2;
  @include media-breakpoint-up(lg) {
    top: rem-calc(60);
  }

  .accordion__button[aria-expanded="true"] {
    .plus-icon {
      transform: rotate(45deg);
    }
  }
  .filter-type-schlagwort {
    position: relative;
    margin-bottom: 0 !important;
    .reset-wrapper {
      position: absolute;
      top: rem-calc(-2);
      left: rem-calc(130);
      @include media-breakpoint-down(md) {
        right: 0;
        left: unset;
      }
      .filter.filter-reset {
        margin-top: 0;
      }
    }
  }
  .filter-type-time-filters {
    margin-bottom: 0 !important;
    @include media-breakpoint-down(md) {
      .collapse-wrapper {
        //margin-bottom: rem-calc(8);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .collapse-wrapper {
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      transition: 250ms background;
      span {
        transition: 250ms color;
      }
      button {
        display: inline-flex;
        flex-flow: row;
      }
      .label {
        display: flex;
        flex-flow: wrap;
        column-gap: rem-calc(8);
        .selected-options {
          display: flex;
          max-width: 90%;
          flex-flow: wrap;
          .option-short {
            &:after {
              content: ",";
              margin-right: rem-calc(4);
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
      &.active {
        color: white;
        background: $dark;
        span {
          color: white;
        }
        .reset-cross {
          filter: invert(1);
        }
      }
      button {
        padding: 0;
        //display: flex;
        //flex-flow: wrap;
        align-items: baseline;
      }
      .reset-cross {
        margin-left: rem-calc(8);
        .cross-icon {
          display: block;
        }
      }
    }
  }

  .plus-icon {
    width: rem-calc(22);
    height: rem-calc(22);
    margin-right: rem-calc(10);
    margin-bottom: rem-calc(4);
  }

  .filter-wrapper {
    display: flex;
    flex-flow: wrap;
    row-gap: rem-calc(5);
    margin-bottom: rem-calc(15);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem-calc(20);
    }
    @include make-col(10);
    .btn {
      @include body(s);
    }


    &.filter-type-schlagwort {
      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }
    }
  }

  .search {
    @include make-col(10);
    margin-top: rem-calc(5);
    margin-bottom: rem-calc(5);
    @include media-breakpoint-up(lg) {
      @include make-col(2);
      margin-top: rem-calc(-5);
      padding-left: rem-calc(24);
    }

    .bx--search-input {
      height: unset;
      padding-left: rem-calc(32);
      padding-right: rem-calc(16);
      background: transparent;
      border-bottom: 2px solid;
      padding-bottom: rem-calc(6);
      outline: none !important;
    }

    .bx--search-close {
      border: none !important;
      outline: none !important;
      justify-content: flex-end;
      height: auto;
      width: auto;
      top: rem-calc(2);

      &:before {
        display: none;
      }

      &:hover {
        background: none;
        outline: none !important;
      }
    }

    .bx--search-magnifier {
      left: rem-calc(5);
      transform: none;
      top: 0;
    }

    input {
      @include body(s);
    }
  }

  .filter-items-wrapper {
    @include media-breakpoint-up(lg) {
      margin-top: rem-calc(15);
    }
  }

  .filter {
    color: $dark;
    @include body(s);
    padding: rem-calc(0) rem-calc(6) rem-calc(0) rem-calc(15);
    display: inline-block;
    margin-right: rem-calc(5);
    @include media-breakpoint-up(lg) {
      margin-right: rem-calc(10);
    }
    margin-top: rem-calc(8);
    cursor: pointer;
    position: relative;

    &.filter-reset {
      @include body(xs);
      margin-top: rem-calc(30);

      .cross-icon {
        display: inline-block;
        bottom: rem-calc(3);
      }
      .name {
        margin-left: rem-calc(8);
      }
      &:hover {
        .name {
          border-bottom: 1px solid;
        }
      }
    }

    .inktrap,
    .cross-icon {
      margin-right: rem-calc(4);
      width: 10px;
      position: absolute;
      left: 0;
      bottom: 0;
      @include body(s);
    }

    .cross-icon {
      left: -2px;
    }

    .ink-trap {
      height: rem-calc(10);
      width: rem-calc(10);
    }

    &.active,
    &:hover {
      .name {
        border-bottom: rem-calc(2) solid;
      }
    }

    &.active {
      .cross-icon {
        display: inline-block;
      }

      .inktrap {
        display: none;
      }
    }
  }
  .filter-type-time-filters {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    column-gap: rem-calc(10);
    .collapse-wrapper {
      border: rem-calc(2) solid $black;
      padding: rem-calc(5) rem-calc(10);
      border-radius: rem-calc(5);
      display: flex;
      flex-flow: column;
      align-self: flex-start;
      @include body(s);
      ul {
        margin-top: rem-calc(8);
        margin-bottom: 0;
        li {
          @include body(s);
          margin-bottom: rem-calc(8);
          cursor: pointer;
          .filter-option {
            position: relative;
            &:before {
              content: "";
              width: rem-calc(16);
              height: rem-calc(16);
              border: 2px solid;
              display: inline-flex;
              margin-right: rem-calc(6);
              transform: translateY(#{rem-calc(2)});
              @include media-breakpoint-up(lg) {
                transform: translateY(-#{rem-calc(2)});
              }
            }
            &.active {
              &:after {
                content: "";
                background: url("/checkmark.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: rem-calc(16);
                height: rem-calc(16);
                left: 0;
                top: 2px;
                display: block;
                position: absolute;
                cursor: pointer;
                @include media-breakpoint-up(lg) {
                  top: rem-calc(6);
                  transform: translateY(-#{rem-calc(2)});
                }
              }
            }
          }
        }
      }
      .collapsing {
        height: auto;
        max-height: 0;
        max-width: 0;
        transition: 250ms max-height, 250ms max-width;
        &.show {
          max-height: rem-calc(500);
          max-width: rem-calc(500);
        }
      }
    }
  }
}

section.search {
  margin: $paragraph-section-padding;
}

section.filter-text,
section.filter-dialog-baum {
  margin: $paragraph-section-padding;

  p {
    @include headline(l);
    display: inline;
  }

  &.filter-dialog-baum {
    p {
      vertical-align: middle;
    }
  }

  .bx--form-item {
    display: inline;
    margin: 0 rem-calc(30) 0 rem-calc(15);

    .bx--select {
      display: inline-block;
    }
  }

  div.filter {
    cursor: pointer;
    display: inline-block;
    background: $pale-grey;
    border-radius: rem-calc(32);
    padding: rem-calc(4) rem-calc(26) rem-calc(4) rem-calc(18);
    margin: 0 rem-calc(15);
    D &.active {
      background: $pale-sky-blue;

      span {
        color: $primary-color;

        svg {
          padding: 0 0 rem-calc(5) 0;
          fill: $primary-color;
        }
      }
    }
  }
}

.pagination {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    li {
      cursor: pointer;

      a {
        display: table;
        padding: rem-calc(15);
        color: black;
      }

      &.selected {
        border: rem-calc(1) solid $primary-color;

        a {
          color: $primary-color;
        }
      }

      &.previous,
      &.next {
        min-width: auto;
        padding: 0;

        a {
          color: white;
        }
      }

      &.previous {
        margin-right: auto;
      }

      &.next {
        margin-left: auto;
      }

      &.disabled {
        visibility: hidden;
      }
    }
  }
}

.paragraph.paragraph-extended-teaser-overview {
  > .container {
    > .row {
      justify-content: flex-start;
    }
  }

  article.node-teaser.teaser-person.teaser-person-overview {
    margin-bottom: rem-calc(70);
  }

  article.node-teaser.teaser-projekt,
  article.node-teaser.teaser-news {
    margin-bottom: rem-calc(30);
  }

  &.type-projekt {
    @include media-breakpoint-up(md) {
      .col-md-custom {
        max-width: 33.333%;
        width: 33.333%;
        flex: 0 0 33.333%;

        &:nth-child(2) {
          margin-top: $whitespace;
        }
      }
      .col-md-custom-highlighted {
        max-width: 100%;
        width: 100%;
        padding: 0 25%;
        //margin: 0 25%;
      }
      .small_big {
        .col-16:not(.col-md-5) {
          @include make-col(11);
        }
      }
    }
  }

  &.type-veranstaltung {
    article.node {
      margin-bottom: 2rem;
    }
  }

  &.type-kindergeburtstag {
  }

  &.type-kurs {
    .kursuebersicht {
      background-color: $body-bg;

      .legend-wrapper {
        padding-top: 1.25rem;
      }
    }
  }

  .legend-wrapper {
    margin-bottom: rem-calc(20);
    padding-top: 1.25rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }

    .legend {
      @include body(xs);
      &:last-child {
        text-align: right;
      }
      &.info {
        display: flex;
        flex-flow: wrap;
        justify-content: flex-end;
        max-width: 5%;
        flex: 0 0 5%;
      }
    }
  }

  .to-top {
    position: relative;
    z-index: 20;
    button {
      position: fixed;
      bottom: rem-calc(-100);
      right: $whitespace;
      font-size: rem-calc(40);
      aspect-ratio: 1 / 1;
      height: rem-calc(52);
      border: 1px solid $black;
      background: $body-bg;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
      padding-top: rem-calc(2);
      transition: 250ms transform ease-in-out, 250ms bottom ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.show {
      button {
        bottom: $whitespace;
      }
    }
  }
}

.show-more-wrapper {
  padding-top: rem-calc(20);
  button {
    svg {
      margin-left: rem-calc(4);
      margin-top: rem-calc(-5);
    }
  }
}

.cross-icon {
  height: rem-calc(12);
  width: rem-calc(12);
  display: none;
  background: url("/cross.svg");
  background-repeat: no-repeat;
  background-size: contain;
  bottom: rem-calc(5);
}
