/* Reset and normalize */
@import "~css-reset-and-normalize/scss/reset-and-normalize.scss";

/* recaptcha */
@import "../../node_modules/react-client-captcha/dist/index.css";

/* react loading spinner */
@import "../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

/*7 React Lightbox */
@import "../../node_modules/lightbox-react/style.css";

/* Carbon */
@import "~carbon-components/scss/components/select/select";
@import "~carbon-components/scss/components/search/search";
@import "~carbon-components/scss/components/button/button";

/* react-accessible-accordion */
//@import '~react-accessible-accordion/dist/fancy-example.css';

/* Slick SCSS */
$slick-font-path: "/font/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick-prev,
.slick-next {
  z-index: 1 !important;
  width: rem-calc(25);
  height: rem-calc(25);
  &::before {
    content: none;
  }
  &:focus,
  &:focus-visible {
    outline: rem-calc(1) solid black;
  }
  svg {
    width: rem-calc(25);
    height: rem-calc(25);
  }
}

.slick-prev {
  left: rem-calc(20);
}

.slick-next {
  right: rem-calc(20);
}

@import "~bootstrap/scss/bootstrap";
@import "_mixins";
@import "forms";

// Components.
@import "footer";
@import "header";
@import "eforkbot";
@import "components/fullscreen-menu";
@import "components/paragraph-hero-text";
@import "components/paragraph-click-suggestions";
@import "components/paragraph-teaser-list";
@import "components/paragraph-teaser-image";
@import "components/paragraph-sectionheading";
@import "components/paragraph-text";
@import "components/paragraph-number-fact";
@import "components/paragraph-teaser-slider";
@import "components/paragraph-newsletteranmeldung";
@import "components/paragraph-akkordion-text";
@import "components/paragraph-person-overview";
@import "components/paragraph-dossier";
@import "components/paragraph-page-title";
@import "components/paragraph-call-to-action";
@import "components/paragraph-extended-teaser-overview";
@import "components/paragraph-calendar-information";
@import "components/paragraph-download";
@import "components/paragraph-zitat";
@import "components/paragraph-intromodul";
@import "components/footer-sitemap";
@import "components/pager-full-page";
@import "components/paragraph-download-schublade";
@import "components/paragraph-timeline";
@import "components/paragraph-strukturierte-liste";
@import "components/paragraph-kalender-einzel-doppel";
@import "components/paragraph-kalender-abschnitt";
@import "components/paragraph-kalender-monat";
@import "components/paragraph-media";
@import "components/paragraph-audio";
@import "components/paragraph-logos-partners";
@import "components/content-cloud/paragraph-content-cloud";
@import "components/content-cloud/detail-overlay";
@import "components/paragraph-text-and-speech";
@import "webform";
@import "tables";
@import "modal";
@import "animations";
@import "controls";
@import "info-box";
@import "special-design";

// Nodes.
article.node-teaser {
  display: flex;

  .flex-wrap {
    display: block;
    width: 100%;
  }

  img {
    margin-bottom: rem-calc(25);
  }

  .top-line {
    display: block;
    @include body(xs);
  }

  h3 {
    @include body(s);
  }

  a {
    color: $black;
    text-decoration: none;
    &:hover {
      text-decoration: none;

      h3 {
        text-decoration: underline;
      }
    }
  }
  .text {
    a {
      text-decoration: underline !important;
    }
  }
}

@import "nodes/node-projekt";
@import "nodes/node-landingpage";
@import "nodes/node-person";
@import "nodes/node-event";
@import "nodes/node-news";
@import "nodes/node-kindergeburtstag";
@import "nodes/node-angebot";
@import "nodes/_node-kurs.scss";

img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.site-logo-button {
  @include header-link();
}

html,
body {
  font-size: inherit;
  font-weight: 600;
}

#app {
  //overflow-x: hidden;
  @include body(s);
  > * {
    @include body(s);
    font-weight: 600;
  }
}

p {
  font-weight: 600;
}

b,
strong {
  font-weight: 700 !important;
  font-family: area-inktrap !important;
}

em {
  font-style: normal !important;
}

.text {
  @include body(s);
  * {
    @include body(s);
  }
  a {
    color: inherit;
  }
  em {
    text-transform: unset !important;
  }
  ul,
  ol {
    margin-left: rem-calc(24) !important;
    li,
    li * {
      @include body(s);
    }
  }
}

em {
  text-transform: unset !important;
}

.accordion__heading {
  cursor: pointer;
  .collapse-icon {
    max-width: 5%;
    flex: 0 0 5%;
  }
}

.node-teaser {
  width: 100%;
  .accordion {
    width: 100%;
    border-bottom: rem-calc(2) solid;
    padding-top: calc(#{$whitespace} / 2);
    padding-bottom: calc(#{$whitespace} / 2);
    .accordion__button {
      > .container {
        max-width: 100%;
        padding: 0;
      }
    }
    h3 {
      margin-bottom: 0;
    }
    .tag-wrapper {
      margin-top: 5px;
      .tag {
        margin-right: calc(#{$whitespace} / 4);
        @include body(xs);
      }
    }
    .collapse-icon {
      display: flex;
      justify-content: flex-end;
    }
    .container.max {
      padding-top: rem-calc(20);
      .text-wrap {
        &.main-text {
          * {
            @include body(s);
          }
        }
        &.additional-text {
          * {
            @include body(xs);
          }
        }
      }
      .text-wrap + .text-wrap {
        margin-top: rem-calc(20);
      }
    }
  }
}

.container {
  &.max {
    max-width: 100%;
    padding: 0;
  }
  > .row {
    > div.grow {
      flex: 1;
      max-width: unset;
    }
  }
}

.paragraph {
  padding: $paragraph-section-padding-mobile;
  @include media-breakpoint-up(md) {
    padding: $paragraph-section-padding;
  }

  h2 {
    @include headline(l);
  }
}

article.node-teaser {
  position: relative;
}

.caption-wrap {
  margin-top: rem-calc(10);

  .caption + .credit {
    margin-left: rem-calc(5);
  }
  .credit {
    @include body(xs);
  }
}

a {
  color: inherit !important;
}

.text {
  a {
    text-decoration: underline;
  }
  strong {
    font-weight: 700 !important;
  }
  h3.heading {
    padding: rem-calc(30) 0;
    margin: 0;
    @include body(s);
  }

  ul {
    list-style: disc;
    margin-left: rem-calc(-5);

    li {
      padding-left: rem-calc(5);
      padding-bottom: rem-calc(15);
    }
  }

  ol {
    list-style: decimal;
    margin-left: rem-calc(-5);

    li {
      padding-left: rem-calc(5);
      padding-bottom: rem-calc(15);
    }
  }
}

p:last-child {
  margin-bottom: 0;
}

.loading-indicator-wrap {
  &.fullpage {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    img {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      animation-iteration-count: infinite;
      height: auto;
      width: 150px;
    }
  }
}

.social-icons-wrapper {
  a {
    padding: 0 0.5rem;
    &:first-child {
      padding-left: 0;
    }
  }
}

.module-wrapper {
  background: var(--module-color);
}

.module-wrapper.different-than-before {
  &.section_heading {
    padding-top: rem-calc(22);
    @include media-breakpoint-up(lg) {
      padding-top: rem-calc(45);
    }
  }
}
